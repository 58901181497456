.contactPageContainer{
    background-color: #E9E5E4;
    min-height: 100vh;
}
.contactPageContents{
    margin-top: 3rem;
    display: flex;
    position: relative;
    align-items: center;
}
.left{
    position: absolute;
    left: 50px;
    top: 40px;
}
.left .form form{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
label{
    margin-right: 5px;
}
input{
    padding: 7px;
    outline: 0;
    width: 300px;
}
textarea{
    padding: 7px;
    outline: 0;
    width: 300px;
}
.right{
    position: absolute;
    right: 50px;
    top: 40px;
}
.socialMedia{
    display: flex;
    gap: 10px;
    flex-direction: column;
    color: brown;
}
.socialMedia img{
    height: 80px;
    width: 80px;
}
