
.nav{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    height: 80px;
}
.navContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #964B00;
    position: absolute;
    left: 40px;
    gap: 3px;
}
.headItem{
    font-weight: bold;
    font-size: 22px;
}
.headItem-1{
    font-size: 16px;
}
.nav ul{
    position: absolute;
    right: 20px;
    top: 10px;
}
.nav ul li{
    display: inline-block;
    list-style: none;
    margin: 10px 20px;
    color: #964B00;
    font-size: 20px;
    position: relative;
    
}
.nav ul li::after{
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    left: 0;
    bottom: -6px;
    transition: 0.5s;
    background:#da4426;

}
.nav ul li:hover{
    cursor: pointer;
}
.nav ul li:hover::after{
    width:100%;
}

